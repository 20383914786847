/**
 * @name: 售后管理-订单取消记录接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 售后管理-订单取消记录接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IAferOrderRes, IAfterOrderCancelParam} from "@/apis/after/orderCancel/types";

/**
 * 订单取消记录
 * @param params
 */
export const manageQueryOrderCancelByPageApi = (params: IAfterOrderCancelParam) => get<IAferOrderRes>("/card/after/sales/manage/queryOrderCancelByPage", params)
/**
 * 订单取消导出
 * @param params
 */
export const statisticsOrderCancelExportApi = (params: IAfterOrderCancelParam) => get("/card/data/statistics/orderCancelExport", params, "blob")
