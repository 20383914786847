
/**
 * @name: 售后管理-订单取消记录
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 售后管理-订单取消记录
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {manageQueryOrderCancelByPageApi, statisticsOrderCancelExportApi} from "@/apis/after/orderCancel"
import { IAfterOrderCancel, IAfterOrderCancelParam } from "@/apis/after/orderCancel/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";

@Component({})
export default class afterOrderCancel extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IAfterOrderCancel[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IAfterOrderCancelParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "用户",
        prop: "userKeywords",
        search: true,
        hide: true,
        placeholder: "输入用户名/手机号"
      },
      {
        label: "退款编号",
        prop: "refundSn",
        width: 180,
        align: "left",
        search: true
      },
      {
        label: "订单编号",
        prop: "orderSn",
        width: 180,
        align: "center",
        search: true
      },
      {
        label: "用户名",
        prop: "userName",
        align: "center",
        width: 150,
        overHidden: true
      },
      {
        label: "手机号",
        prop: "userPhone",
        align: "center",
        width: 150
      },
      {
        label: "订单金额",
        prop: "orderPrice",
        align: "center"
      },
      {
        label: "商品金额",
        prop: "productPrice",
        align: "center"
      },
      {
        label: "运费",
        prop: "freightPrice",
        align: "center"
      },
      {
        label: "礼品卡抵扣金额",
        prop: "couponPrice",
        align: "center",
        width: 150
      },
      {
        label: "实付金额",
        prop: "payPrice",
        align: "center"
      },
      {
        label: "退款金额",
        prop: "refundPrice",
        align: "center"
      },
      {
        label: "退回礼品卡",
        prop: "refundCouponPrice",
        align: "center",
        // @ts-ignore
        default: '0.00'
      },
      {
        label: "退回微信",
        prop: "refundWeChat",
        align: "center"
      },
      {
        label: "取消原因",
        prop: "cancelRemarks",
        align: "center",
        overHidden: true
      },
      {
        label: "取消时间",
        prop: "cancelTime",
        align: "center",
        width: 150,
        search: true,
        type: "daterange"
      },
    ]
  }

  refundPriceTotal = 0

  getList () {
    this.tableLoading = true

    const query: IAfterOrderCancelParam = deepCopy(this.queryParam)

    if (query.cancelTime && query.cancelTime.length) {
      query.cancelTimeStart = query.cancelTime[0]
      query.cancelTimeEnd = query.cancelTime[1]
    } else {
      query.cancelTimeStart = ''
      query.cancelTimeEnd = ''
    }
    delete query.cancelTime

    manageQueryOrderCancelByPageApi(query).then(e => {
      if (e) {
        this.tableData = e.commonPage.list
        this.tableTotal = e.commonPage.total

        this.refundPriceTotal = e.refundPriceTotal;

        this.tableLoading = false
      }
    })
  }

  exportExcel () {
    const query: IAfterOrderCancelParam = deepCopy(this.queryParam)

    if (query.cancelTime && query.cancelTime.length) {
      query.cancelTimeStart = query.cancelTime[0]
      query.cancelTimeEnd = query.cancelTime[1]
    } else {
      query.cancelTimeStart = ''
      query.cancelTimeEnd = ''
    }
    delete query.cancelTime

    statisticsOrderCancelExportApi(query).then(e => {
      exportFile(e, "订单取消记录.xlsx")
    })
  }

  created () {
    this.getList()
  }
}
